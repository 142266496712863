import React, { Component } from 'react';
import './Pagination.scss';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const style = {
    arrowMargin: {
        marginRight: "4px"
    },
    margin0: {
        marginRight: 0
    }
};

class Pagination extends Component {
    constructor(props) {
        super(props);

        this.state = {
            total: this.props.total,
            page: this.props.page,
            listPerPage: this.props.listPerPage,
            step: this.props.step
        };

        this.generatePage = this.generatePage.bind(this);
        this.printFirstPage = this.printFirstPage.bind(this);
        this.printMidPage = this.printMidPage.bind(this);
        this.printLastPage = this.printLastPage.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.listPerPage !== this.props.listPerPage) {
            this.setState({ listPerPage: this.props.listPerPage });
        }
        if (prevProps.total !== this.props.total) {
            this.setState({ total: this.props.total });
        }
        if (prevProps.page !== this.props.page) {
            this.setState({ page: this.props.page });
        }
    }

    getTotalPage() {
        let _total = 0;

        _total = Math.ceil(this.state.total / this.state.listPerPage);

        return _total;
    }

    generatePage() {
        let totalPage = Math.ceil(this.state.total / this.state.listPerPage);
        let pageList = [];

        if (totalPage < this.state.step * 2 + 6) {

            pageList.push(this.printMidPage(1, totalPage + 1));

        } else if (this.state.page < this.state.step * 2 + 1) {

            pageList.push(this.printMidPage(1, this.state.step * 2 + 4));
            pageList.push(this.printLastPage(totalPage));

        }
        else if (this.state.page > totalPage - this.state.step * 2) {

            pageList.push(this.printFirstPage());
            pageList.push(this.printMidPage(totalPage - this.state.step * 2 - 2, totalPage + 1));

        }
        else {

            pageList.push(this.printFirstPage());
            pageList.push(this.printMidPage(this.state.page - this.state.step, this.state.page + this.state.step + 1));
            pageList.push(this.printLastPage(totalPage));

        }

        return pageList;
    }

    printFirstPage() {
        let pageFirst = [];
        pageFirst.push(
            <li onClick={() => this.changePageState(1)}
                className={this.state.page === 1 ? "active pagination-button" : "pagination-button"}
                key={"first-page"}
            >
                <span>{1}</span>
            </li>
        );
        pageFirst.push(<li key="first-dot">...</li>);

        return pageFirst;
    }

    printMidPage(page, totalPage) {
        let pageMid = [];
        for (let i = page; i < totalPage; i++) {
            pageMid.push(
                <li onClick={() => this.changePageState(i)}
                    className={this.state.page === i ? "active pagination-button" : "pagination-button"}
                    key={i}
                >
                    <span>{i}</span>
                </li>
            );
        }

        return pageMid;
    }

    printLastPage(totalPage) {
        let pageLast = [];
        pageLast.push(<li key="last-dot">...</li>);
        pageLast.push(
            <li onClick={() => this.changePageState(totalPage)}
                className={this.state.page === totalPage ? "active pagination-button" : "pagination-button"}
                key={"last-page"}
            >
                <span>{totalPage}</span>
            </li>
        );

        return pageLast;
    }

    changePageState(page) {
        this.setState({ page: page });
        this.props.onChangePage(page);
    }

    render() {
        return (
            <div id="pagination-wrapper">
                <ul>
                    {(this.getTotalPage() > 1 && this.state.page !== 1) &&
                        <li className="pagination-label pagination-button"
                            onClick={() => this.changePageState(this.state.page - 1)}
                            key={"previous-page"}
                        >
                            <IoIosArrowBack size="18" />
                        </li>
                    }

                    {this.generatePage()}

                    {(this.getTotalPage() > 1 && this.state.page !== Math.ceil(this.props.total / this.state.listPerPage)) &&
                        <li className="pagination-label pagination-button"
                            style={style.arrowMargin}
                            onClick={() => this.changePageState(this.state.page + 1)}
                            key={"next-page"}
                        >
                            <IoIosArrowForward size="18" />
                        </li>
                    }
                </ul>
            </div>
        )
    }
}

export default Pagination;
