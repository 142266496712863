import React, { useState, useEffect } from "react";
import "./EditBusinessProfile.scss";
import HelperInput from "../../../../../../helper/HelperInput";
import SectionWrap from "../../../../../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from "../../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiPlus, FiTrash2, FiInfo } from "react-icons/fi";
import CustomSelectOption from "../../../../../reuseableComponent/customSelectOption/CustomSelectOption";
import BackButton from "../../../../../reuseableComponent/BackButton/BackButton";
import ErrorOrInfoComp from "../../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import IntegrationWhatsappBusinessServices from "../../../../../../services/integration/IntegrationWhatsappBusinessServices";
import ServiceOrganization from "../../../../../../services/organization/ServiceOrganization";
import { checkID, doToast, scrollToClass } from "../../../../../../helper/HelperGeneral";
import RetrieveDataLoading from "../../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
// import { disable } from "mixpanel-browser";

let EditBusinessProfile = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  // let [data, setData] = useState({
  //   address: "",
  //   description: "",
  //   email: "",
  //   vertical: "",
  //   websites: [],
  // });
  let [description, setDescription] = useState("");
  let [address, setAddress] = useState("");
  let [email, setEmail] = useState("");
  let [websites, setWebsites] = useState([]);
  let [industryVal, setIndustryVal] = useState({
    label: "",
    value: "",
  });
  let [isLoadingSaveChanges, setIsLoadingSaveChanges] = useState(false);
  let [industryList, setIndustryList] = useState([]);
  let [errorMessageData, setErrorMessageData] = useState({
    description: "",
    address: "",
    industry: "",
    email: "",
    website: "",
    website2: ""
  })

  let onSelectIndustry = (value) => {
    let _errorMessageData = { ...errorMessageData };
    _errorMessageData.industry = "";
    setErrorMessageData(_errorMessageData);
    setIndustryVal(value);
  };

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
      getAccountDetail();
      getVerticalOptions();
    }
  }, [orgID]);

  let getVerticalOptions = () => {
    IntegrationWhatsappBusinessServices.getVerticalOptions(
      orgID,
      { id: Number(match.params.id) },
      (response) => {
        let _retriveDataError = { ...retriveDataError };

        if (response.dataResult.error.message === "") {
          let _verticals = [];
          response.dataResult.data.verticals.map((item, idx) => {
            _verticals.push({ value: idx, label: item });
            return null;
          });
          setIndustryList(_verticals);
        } else {
          _retriveDataError.message = response.dataResult.error.message;
          _retriveDataError.code = response.dataResult.error.code;
          setRetrieveDataError(_retriveDataError);
        }

        setIsLoadingGetDetailData(false);
      }
    );
  };

  let getAccountDetail = () => {
    setIsLoadingGetDetailData(true);

    let data = {
      id: Number(match.params.id),
      phoneNumberID: match.params.phone
    };

    IntegrationWhatsappBusinessServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        // setData(response.dataResult.data.settings.businessProfile);
        // setData({
        //     address: "test",
        //     description: "test",
        //     email: "fufu@fufu.com",
        //     vertical: "Other",
        //     websites: ["test1.com", "test2.com"]
        // })
        // if(["test1.com", "test2.com"].length > 1) {
        //       setIsAddAnotherWebActive(true)
        //   }
        // setDescription("test")
        // setAddress("test")
        // setEmail("test@test.com")
        // setWebsites(["test1.com", "test2.com"])

        if (response.dataResult.data.settings.businessProfile.websites
          && response.dataResult.data.settings.businessProfile.websites.length > 1) {
          setIsAddAnotherWebActive(true)
        }
        setDescription(
          response.dataResult.data.settings.businessProfile.description
        );
        setAddress(response.dataResult.data.settings.businessProfile.address);
        setEmail(response.dataResult.data.settings.businessProfile.email);
        setWebsites(response.dataResult.data.settings.businessProfile.websites);
        setIndustryVal({
          label: response.dataResult.data.settings.businessProfile.vertical,
          value: response.dataResult.data.settings.businessProfile.vertical,
        });
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let [isAddAnotherWebActive, setIsAddAnotherWebActive] = useState(false);

  let addAnotherWeb = () => {
    if (!isLoadingSaveChanges) {
      let _errorMessageData = { ...errorMessageData };
      _errorMessageData.website2 = "";
      setErrorMessageData(_errorMessageData);
      setIsAddAnotherWebActive(true);
    }
  };

  // conisButtonDisabledst isButtonDisabled = () => {
  //   let disabled = true;

  //   if (isLoadingSaveChanges) return true;

  //   if (description !== data.description) disabled = false;
  //   else if (address !== data.address) disabled = false;
  //   else if (email !== data.email) disabled = false;
  //   else if (industryVal.label !== data.vertical) disabled = false;
  //   else if (data.websites && websites.length !== data.websites.length) disabled = false;
  //   else if (data.websites && websites[0] !== data.websites[0]) disabled = false;
  //   else if (data.websites && websites[1] !== data.websites[1]) disabled = false;

  //   return disabled;
  // };

  let saveChanges = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = { ...errorMessageData };

    // if(description === "") {
    //     _errorMessageData.description = "This field is required";
    //     errorCount = errorCount + 1;

    //     if(errorClass === "") { errorClass = "input-description" }
    // }

    if (description.length > 256) {
      _errorMessageData.description = "Characters exceed limit";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-description" }
    }

    // if(address === "") {
    //   _errorMessageData.address = "This field is required";
    //   errorCount = errorCount + 1;

    //   if(errorClass === "") { errorClass = "input-address" }
    // }

    // if(industryVal.label === "") {
    //   _errorMessageData.industry = "Please select industry";
    //   errorCount = errorCount + 1;

    //   if(errorClass === "") { errorClass = "input-industry" }
    // }

    // if(email === "") {
    //   _errorMessageData.email = "This field is required";
    //   errorCount = errorCount + 1;

    //   if(errorClass === "") { errorClass = "input-email" }
    // }

    if (email === "") {
      _errorMessageData.email = "This field is required";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-email" }
    }

    if (!HelperInput.emailFormatValid(email) && email !== "") {
      _errorMessageData.email = "Invalid email format";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-email" }
    }

    if (email.length > 250) {
      _errorMessageData.email = "Characters exceed limit";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-email" }
    }

    // if(websites[0] === "" || websites.length === 0) {
    //   _errorMessageData.website = "This field is required";
    //   errorCount = errorCount + 1;

    //   if(errorClass === "") { errorClass = "input-website" }
    // }

    // if (isAddAnotherWebActive) {
    //   if (websites.length === 1) {
    //     _errorMessageData.website2 = "This field is required";
    //     errorCount = errorCount + 1;

    //     if (errorClass === "") { errorClass = "input-website2" }
    //   }
    // }

    if (errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    } else {
      setIsLoadingSaveChanges(true);

      let data = {
        id: Number(match.params.id),
        phoneNumberID: match.params.phoneID,
        address: address,
        description: description,
        email: email,
        vertical: industryVal.label,
        websites: websites,
      };
      IntegrationWhatsappBusinessServices.editProfile(orgID, data, (response) => {
        if (response.dataResult.error.message === "") {
          history.push({
            pathname: `/o/${orgID}/integration/whatsapp-business-api/detail/${match.params.id}`,
            state: { whichTab: "businessProfile" },
          });
        } else {
          doToast(response.dataResult.error.message, "fail");
        }
        setIsLoadingSaveChanges(false);
      });
    }
  };

  return (
    <div className="edit-business-profile-outer-wrapper">
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        <BackButton
          text="Back to Business Profile"
          onClick={() => {
            history.push({
              pathname: `/o/${orgID}/integration/whatsapp-business-api/detail/${match.params.id}`,
              state: { whichTab: "businessProfile" },
            });
          }}
        />

        <div className="edit-business-profile-whatsapp sectionWrap">
          {isLoadingGetDetailData || retriveDataError.code ? (
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message}
              />
            </div>
          ) : (
            <>
              <div className="edit-business-profile-header">
                <div className="title-wrapper">
                  <b>Edit Business Profile</b>
                </div>
                <div className="button-wrapper">
                  <ButtonWithLoadingOrIcon
                    className="orange-button  main-button-40"
                    text="Save Changes"
                    // isDisabled={isButtonDisabled()}
                    isDisabled={isLoadingSaveChanges}
                    isLoading={isLoadingSaveChanges}
                    onClickAction={saveChanges}
                    position="left"
                    loadingColor="gray"
                  />
                </div>
              </div>
              <div className="edit-business-profile-content">
                <div className="business-profile-content-wrapper">
                  <div className="label-wrapper">
                    <label>
                      <b>Description </b>

                      <span className="text-optional grey-font">
                        (optional)
                      </span>
                    </label>
                    <div className={`count-char`}>
                      <p className={`${errorMessageData.description === "Characters exceed limit" ? "font-red" : ""}`}>{document.getElementById('edit-business-description') ?
                        document.getElementById('edit-business-description').value.length : 0}/256 characters</p>
                    </div>
                  </div>
                  <div className="edit-input-wrapper">
                    <textarea
                      name="about"
                      placeholder="A short description about your bussiness..."
                      rows="5"
                      // maxLength="256"
                      id="edit-business-description"
                      defaultValue={description}
                      onChange={(e) => {
                        let _errorMessageData = { ...errorMessageData };
                        _errorMessageData.description = "";
                        setErrorMessageData(_errorMessageData);
                        setDescription(e.target.value)
                      }}
                      disabled={isLoadingSaveChanges}
                      className={`input-description ${errorMessageData.description !== "" ? "border-red" : ""}`}
                    />
                    {errorMessageData.description !== "" &&
                      <ErrorOrInfoComp
                        text={errorMessageData.description}
                        _className={"font-red margin-top-0"}
                        icon={<FiInfo />}
                      />
                    }
                  </div>
                </div>

                <div className="business-profile-content-wrapper">
                  <div className="label-wrapper">
                    <label>
                      <b>Address </b>
                      <span className="text-optional grey-font">
                        (optional)
                      </span>
                    </label>
                  </div>
                  <div className="edit-input-wrapper">
                    <textarea
                      name="about"
                      placeholder="Full address of where your business is located...."
                      id="edit-business-address"
                      rows="5"
                      defaultValue={address}
                      onChange={(e) => {
                        let _errorMessageData = { ...errorMessageData };
                        _errorMessageData.address = "";
                        setErrorMessageData(_errorMessageData);
                        setAddress(e.target.value)
                      }}
                      disabled={isLoadingSaveChanges}
                      className={`input-address ${errorMessageData.address !== "" ? "border-red" : ""}`}
                    ></textarea>
                    {errorMessageData.address !== "" &&
                      <ErrorOrInfoComp
                        text={errorMessageData.address}
                        _className={"font-red margin-top-0"}
                        icon={<FiInfo />}
                      />
                    }
                  </div>
                </div>
                <div className="business-profile-content-wrapper">
                  <div className="label-wrapper">
                    <label>
                      <b>Industry </b>
                      <span className="text-optional grey-font">
                        (optional)
                      </span>
                    </label>
                  </div>
                  <div className="edit-input-wrapper">
                    <CustomSelectOption
                      optionListProps={industryList}
                      valueDropdownProps={industryVal}
                      placeholderProps={"Select an industry"}
                      onClickDropDownListOptionProps={onSelectIndustry}
                      isDisabled={isLoadingSaveChanges}
                      _className={`input-industry ${errorMessageData.industry === "" ? "" : "border-red"}`}
                    />
                    {errorMessageData.industry !== "" &&
                      <ErrorOrInfoComp
                        text={errorMessageData.industry}
                        _className={"font-red margin-top-0"}
                        icon={<FiInfo />}
                      />
                    }
                  </div>
                </div>
                <div className="business-profile-content-wrapper">
                  <div className="label-wrapper">
                    <label>
                      <b>Email </b>
                      <span className="text-optional grey-font">
                        (optional)
                      </span>
                    </label>
                    <div className={`count-char`}>
                      <p className={`${errorMessageData.email === "Characters exceed limit" ? "font-red" : ""}`}>{document.getElementById('edit-business-description') ?
                        document.getElementById('edit-business-email').value.length : 0}/250 characters</p>
                    </div>
                  </div>
                  <div className="edit-input-wrapper">
                    <input
                      type="text"
                      placeholder="work@email.com"
                      id="edit-business-email"
                      defaultValue={email}
                      onChange={(e) => {
                        let _errorMessageData = { ...errorMessageData };
                        _errorMessageData.email = "";
                        setErrorMessageData(_errorMessageData);
                        setEmail(e.target.value)
                      }}
                      disabled={isLoadingSaveChanges}
                      className={`input-email ${errorMessageData.email !== "" ? "border-red" : ""}`}
                    />
                    {errorMessageData.email !== "" &&
                      <ErrorOrInfoComp
                        text={errorMessageData.email}
                        _className={"font-red"}
                        icon={<FiInfo />}
                      />
                    }
                  </div>
                </div>

                {(
                  <div className="business-profile-content-wrapper">
                    <div className="label-wrapper">
                      <label>
                        <b>Website </b>
                        <span className="text-optional grey-font">
                          (optional)
                        </span>
                      </label>
                    </div>
                    <div className="edit-input-wrapper" key={`website-1`}>
                      <input
                        type="text"
                        placeholder="http://www.business.com"
                        defaultValue={websites ? websites[0] : ""}
                        id="add-business-web-one"
                        onChange={(e) => {
                          // console.log(e.target.value)
                          let _websites = websites ? [...websites] : [];
                          if (!e.target.value) {
                            _websites.splice(0, 1)
                            // console.log(_websites)
                            setWebsites(_websites);
                          } else {
                            let website2 = document.getElementById('add-business-web-two')
                            website2 = (website2 ? website2.value : "");
                            if (website2) {
                              let temp = website2
                              _websites[0] = e.target.value
                              _websites[1] = temp
                            } else {
                              _websites[0] = e.target.value;
                            }

                            let _errorMessageData = { ...errorMessageData };
                            _errorMessageData.website = "";
                            setErrorMessageData(_errorMessageData);
                            setWebsites(_websites);
                          }
                        }}
                        disabled={isLoadingSaveChanges}
                        className={`input-website ${errorMessageData.website !== "" ? "border-red" : ""}`}
                      />
                      {errorMessageData.website !== "" &&
                        <ErrorOrInfoComp
                          text={errorMessageData.website}
                          _className={"font-red"}
                          icon={<FiInfo />}
                        />
                      }
                    </div>
                  </div>
                )}

                {websites && (isAddAnotherWebActive) && (
                  <div className="business-profile-content-wrapper">
                    <div className="label-wrapper">
                      <label>
                        <b>Website 2</b>
                      </label>
                    </div>
                    <div className="edit-input-wrapper edit-another-website">
                      <input
                        type="text"
                        placeholder="http://www.business.com"
                        defaultValue={websites ? websites[1] : ""}
                        id="add-business-web-two"
                        onChange={(e) => {
                          let _websites = websites ? [...websites] : [];
                          if (!e.target.value) {
                            _websites.pop()
                            // console.log(_websites)
                            setWebsites(_websites);
                          } else {
                            let website1 = document.getElementById('add-business-web-one');
                            website1 = (website1 ? website1.value : "")
                            if (!website1) {
                              _websites[0] = e.target.value;
                            } else {
                              _websites[1] = e.target.value;
                            }

                            let _errorMessageData = { ...errorMessageData };
                            _errorMessageData.website2 = "";
                            setErrorMessageData(_errorMessageData);
                            setWebsites(_websites);
                          }
                        }}
                        disabled={isLoadingSaveChanges}
                        className={`input-website2 ${errorMessageData.website2 !== "" ? "border-red" : ""}`}
                      />

                      <FiTrash2
                        onClick={() => {
                          if (!isLoadingSaveChanges) {
                            // let _websites = [];
                            // _websites[0] = websites[0];
                            let _websites = websites ? [...websites] : []
                            _websites.pop()
                            // console.log(_websites)
                            setWebsites(_websites);
                            setIsAddAnotherWebActive(false);
                          }
                        }}
                      />
                    </div>

                    {errorMessageData.website2 !== "" &&
                      <ErrorOrInfoComp
                        text={errorMessageData.website2}
                        _className={"font-red"}
                        icon={<FiInfo />}
                      />
                    }
                  </div>
                )}
                {websites && websites.length < 2 && !isAddAnotherWebActive && (
                  <div
                    className="add-another-website-wrapper"
                    onClick={addAnotherWeb}
                  >
                    <FiPlus />
                    <b>Add Another Website</b>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </SectionWrap>
    </div>
  );
};

export default EditBusinessProfile;